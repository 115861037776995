/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'

import SlotGeneric from '@hmn/rtl-web-core/components/Ads/Slot/CombinedSlot.component'
import VoyoPlaceholder from '@hmn/rtl-web-core/components/VoyoPlaceholder/VoyoPlaceholder.component'

const Slot = ({ ...rest }) => (
    <SlotGeneric
        placeholderProps={{
            buttonHref: 'https://voyo.rtl.hr/?utm_source=zena.hr&utm_medium=placeholder&utm_campaign=voyo'
        }}
        {...rest}
    />
)

Slot.propTypes = Object.assign({}, SlotGeneric.propTypes)

Slot.defaultProps = {
    ...SlotGeneric.defaultProps,
    variant: undefined,
    PlaceholderComponent: VoyoPlaceholder
}
export default Slot
